// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {Box, Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SimpleDialog from "../SimpleDialog";

const useStyles = makeStyles((theme) => ({
  root: ({maxWidth, topGutter, topGutterSpacing, bottomGutter, minHeight}) => ({
    maxWidth: maxWidth || 800,
    paddingTop: theme.spacing(topGutterSpacing || (topGutter ? 4 : 0)),
    paddingBottom: theme.spacing(bottomGutter ? 10 : 0),
    minHeight: minHeight || undefined,
  }),
}));

export default function BodyContainer(props) {
  const classes = useStyles(props);
  const {errorMessage, errorTitle, children, handleResetError} = props;

  return (
    <Box>
      {Boolean(errorMessage) && (
        <SimpleDialog
          id="body-error-dialog"
          toggleDialog={handleResetError}
          open={true}
          title={errorTitle || "An error occurred"}
          description={errorMessage}
          primaryActionText="OK"
          handlePrimaryAction={handleResetError}
        />
      )}
      <Container className={classes.root} maxWidth="lg">
        {children}
      </Container>
    </Box>
  );
}

BodyContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  errorMessage: PropTypes.string,
};
