// React
import React from "react";
import {navigate} from "gatsby";

// Components
import {PublicNavbar} from "../../components/Navbars";
import BodyContainer from "../../components/BodyContainer";
import LoadingIndicator from "../../components/LoadingIndicator";
import TeamCard from "../../components/TeamCard";
import Footer from "../../components/Footer";

// Material UI
import {Box, Typography} from "@material-ui/core";
import { FirebaseContext, useFetchFirestore } from "../../components/Firebase";

// Constants
import * as routes from "../../constants/routes";

export default function Index(props) {
  const firebase = React.useContext(FirebaseContext);

  let teamsRef = null;
  if (firebase) {
    teamsRef = firebase.teams()
      .where("status", "==", "approved")
  }

  const [teams, loadingTeams] = useFetchFirestore(
    teamsRef ? teamsRef.limit(10) : null,
    { type: "collection" }
  )

  return (
    <Box style={{background: "white"}}>
      <PublicNavbar/>
      <BodyContainer topGutter bottomGutter minHeight={1000}>
        {loadingTeams ? (
          <LoadingIndicator 
            message="Loading teams..." 
            inheritHeight 
            topSpacing={10}
          />
        ) : (
          <Box>
            <Typography variant="h4" component="h1" style={{ marginBottom: 24 }}>Teams</Typography>
            {teams.map(team => (
              <TeamCard
                {...team}
                key={team.uid}
                onClick={() => navigate(`${routes.teamProfile}?uid=${team.uid}`)}
              />
            ))}
          </Box>
          
        )}
        {/*<Hero*/}
        {/*  id="top-hero"*/}
        {/*  primaryText="Where advertising freelancers work"*/}
        {/*  primaryTextVariant="h3"*/}
        {/*  secondaryText="Join an exclusive community. Work on well-paid advertising projects for leading companies. Focus on improving client advertising results. Maxer handles the rest."*/}
        {/*  imgSizes={topHeroImage.sizes}*/}
        {/*/>*/}
      </BodyContainer>
      <Footer/>
    </Box>
  );
}